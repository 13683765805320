import React from 'react';
import './App.css';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Button,
    Drawer,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@material-ui/core";
import {
    Menu,
    Business
} from "@material-ui/icons";

import './style/LandingPageStyle.scss';

import campusImage from "./images/campus.png";

function LandingPage(props) {
    return (
        <React.Fragment>
            <div style={{position: "fixed", top: 0, left: 0, right: 0, bottom: 0, background: "url(" + campusImage + ")", backgroundRepeat: "round", zIndex: -1}} />

            <Link to="/floorplans/horizon" className="floorplan-button" style={{top: 0, left: 0, right: "55vw", bottom: 0}}>
                <div>Horizon</div>
            </Link>
            <Link to="/floorplans/frontier" className="floorplan-button" style={{top: 0, left: "45vw", right: 0, bottom: "40vh"}}>
                <div>Frontier</div>
            </Link>
            <Link to="/floorplans/ocean" className="floorplan-button" style={{top: "60vh", left: "45vw", right: 0, bottom: 0}}>
                <div>Ocean</div>
            </Link>
        </React.Fragment>
    )
}

function Sidebar() {
    return (
        <Drawer
            variant="persistent"
            anchor="left"
            open={true}
        >
            <Divider />
            <List>
                <Link to="/floorplans/frontier">
                    <ListItem button>
                        <ListItemIcon><Business/></ListItemIcon>
                        <ListItemText primary="Frontier" />
                    </ListItem>
                </Link>
                <Link to="/floorplans/horizon">
                    <ListItem button>
                        <ListItemIcon><Business/></ListItemIcon>
                        <ListItemText primary="Horizon" />
                    </ListItem>
                </Link>
                <Link to="/floorplans/ocean">
                    <ListItem button>
                        <ListItemIcon><Business/></ListItemIcon>
                        <ListItemText primary="Ocean" />
                    </ListItem>
                </Link>
            </List>
        </Drawer>
    );
}

function HorizonFloorplan(props) {
    return (
        <h1>Horizon</h1>
    );
}
function FrontierFloorplan(props) {
    return (
        <h1>Fronier</h1>
    );
}
function OceanFloorplan(props) {
    return (
        <h1>Ocean</h1>
    );
}

function Floorplan(props) {
    return (
        <React.Fragment>
            <Sidebar/>
            <div style={{ flexGrow: "1" }}>
                <Switch>
                    <Route path="/floorplans/horizon" component={HorizonFloorplan} />
                    <Route path="/floorplans/frontier" component={FrontierFloorplan} />
                    <Route path="/floorplans/ocean" component={OceanFloorplan} />
                </Switch>
            </div>
        </React.Fragment>
    )
}

function App() {
    return (
        <React.Fragment>
            <div style={{ display: "flex" }}>
                <AppBar position="fixed">
                    <Toolbar>
                        <IconButton edge="start" color="inherit" aria-label="menu">
                            <Menu />
                        </IconButton>
                        <Typography variant="h6">
                            Floorplan
                        </Typography>
                        <Button color="inherit">Login</Button>
                    </Toolbar>
                </AppBar>

                <Router>

                    <Switch>
                        <Route path="/floorplans" component={Floorplan} />
                        <Route path="/" exact component={LandingPage} />
                    </Switch>

                </Router>
            </div>
        </React.Fragment>
    );
}

export default App;
